const STABLE_ARRAY: unknown[] = [];

/** Returns a stable empty array with a type inferred from its usage location  */
export function stableEmptyArray<T = unknown>(): readonly T[] {
  return STABLE_ARRAY as T[];
}

const STABLE_OBJ: { [k: string]: unknown } = {};

/** Returns a stable empty object reference with a type inferred from its usage location  */
export function stableEmptyObject<K extends string, V>(): Record<K, V> {
  return STABLE_OBJ as Record<K, V>;
}

const STABLE_SET: ReadonlySet<unknown> = new Set();

/** Returns a stable empty set reference with a type inferred from its usage location  */
export function stableEmptySet<V>(): ReadonlySet<V> {
  return STABLE_SET as ReadonlySet<V>;
}
