import { Literal, Static, Union } from "runtypes";

import {
  FilledDynamicValueTable,
  FilledDynamicValueTableContents,
  FilledDynamicValueTableHeader,
} from "./DynamicValue";
import { getNormalEnum } from "./runtypeEnums";

export const TableInputColumnTypeLiteral = Union(
  Literal("STRING"),
  Literal("NUMBER"),
  Literal("BOOLEAN"),
);
export type TableInputColumnType = Static<typeof TableInputColumnTypeLiteral>;
export const TableInputColumnType = getNormalEnum(TableInputColumnTypeLiteral);

export const TableInputHeader = FilledDynamicValueTableHeader;
export type TableInputHeader = Static<typeof FilledDynamicValueTableHeader>;

export const TableInputContents = FilledDynamicValueTableContents;
export type TableInputContents = Static<typeof FilledDynamicValueTableContents>;

// TODO - right now FilledDynamicValueTable is doing double duty of both upstream and entry
// types for table inputs. They are not completly 1:1 (see column types), so they should most likely
// be seperate runtypes, however, this requires a careful refactor of the components that use this type.
export const TableInputData = FilledDynamicValueTable;
export type TableInputData = Static<typeof FilledDynamicValueTable>;

const VALID_NAN_NUMBER_STRINGS = ["", "nan"];
export const floatValidator = (
  value: string | null | undefined | number | boolean,
): boolean => {
  if (typeof value === "boolean") {
    return false;
  }

  return (
    value == null ||
    typeof value === "number" ||
    (typeof value === "string" &&
      VALID_NAN_NUMBER_STRINGS.includes(value.trim().toLowerCase())) ||
    !isNaN(Number(value))
  );
};

// Invalid numbers convert to `null` which in turn becomes `NaN` in pandas
export const floatConverter = (
  value: string | null | undefined | number | boolean,
): number | null => {
  if (typeof value === "boolean" || value == null) {
    return null;
  }

  if (typeof value === "string") {
    if (value.trim() === "") {
      return null;
    }

    const numVal = Number(value);
    return isNaN(numVal) ? null : numVal;
  }

  return value;
};

const BOOLEAN_STRINGS = ["true", "false", ""];
export const booleanValidator = (
  value: string | null | undefined | number | boolean,
): boolean => {
  return (
    typeof value === "boolean" ||
    value == null ||
    (typeof value === "string" &&
      BOOLEAN_STRINGS.includes(value.trim().toLowerCase()))
  );
};

// Invalid values convert to `false` because Pandas bool columns don't support null
export const booleanConverter = (
  value: string | null | undefined | number | boolean,
): boolean => {
  return value === true || value?.toString().trim().toLowerCase() === "true";
};

export const stringConverter = (
  value: string | null | undefined | number | boolean,
): string => {
  // just make sure that nullish values become empty strings
  return value?.toString() ?? "";
};
