/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import {
  Array,
  Boolean,
  Literal,
  Null,
  Number,
  Optional,
  Partial,
  Record as RRecord,
  Static,
  String,
  Union,
} from "runtypes";

import { DynamicValue, DynamicValueTypes } from "./DynamicValue";
import { NumberArray, StringArray } from "./parameterValueType";
import { getNormalEnum } from "./runtypeEnums";

export const ParameterInputTypeLiteral = Union(
  Literal("TEXT_INPUT"),
  Literal("NUMERIC_INPUT"),
  Literal("DROPDOWN"),
  Literal("TABLE"),
  Literal("SLIDER"),
  Literal("DATE"),
  Literal("BUTTON"),
  Literal("CHECKBOX"),
  Literal("MULTISELECT"),
  Literal("FILE_UPLOAD"),
);
export type ParameterInputType = Static<typeof ParameterInputTypeLiteral>;
export const ParameterInputType = getNormalEnum(ParameterInputTypeLiteral);

export const ParameterOutputTypeLiteral = Union(
  Literal("STRING"),
  Literal("NUMBER"),
  Literal("DATA_FRAME"),
  Literal("DATETIME"),
  Literal("BOOLEAN"),
  Literal("DYNAMIC"),
  Literal("LIST_STRING"),
  Literal("LIST_NUMBER"),
);
export type ParameterOutputType = Static<typeof ParameterOutputTypeLiteral>;
export const ParameterOutputType = getNormalEnum(ParameterOutputTypeLiteral);

export const DynamicOrStaticList = Union(
  StringArray,
  NumberArray,
  DynamicValueTypes,
);
export type DynamicOrStaticList = Static<typeof DynamicOrStaticList>;

export const DropdownOptions = RRecord({
  valueOptions: DynamicOrStaticList,
});
export type DropdownOptions = Static<typeof DropdownOptions>;

export const TextOptions = RRecord({
  multiline: Boolean,
});
export type TextOptions = Static<typeof TextOptions>;

export const SliderOptions = RRecord({
  min: Number,
  max: Number,
  step: Number,
});
export type SliderOptions = Static<typeof SliderOptions>;

export const NumericOptions = RRecord({
  increment: Number,
});
export type NumericOptions = Static<typeof NumericOptions>;

export const DatetimeOptions = RRecord({
  enableTime: Boolean,
  showRelativeDates: Optional(Boolean),
  dynamicDefault: Optional(DynamicValue),
  useDateRange: Optional(Boolean),
});
export type DatetimeOptions = Static<typeof DatetimeOptions>;

export const ButtonOptions = RRecord({
  intent: String,
  icon: String,
  text: String,
});
export type ButtonOptions = Static<typeof ButtonOptions>;

export const CheckboxOptions = RRecord({
  style: Literal("checkbox").Or(Literal("switch")),
  text: String,
});
export type CheckboxOptions = Static<typeof CheckboxOptions>;

export const TableOptions = RRecord({
  value: DynamicValue.Or(Null),
});
export type TableOptions = Static<typeof TableOptions>;

// Note: option types must all be distinct or it can lead to typeguard issues
export const MultiSelectOptions = RRecord({
  multiValueOptions: DynamicOrStaticList,
});
export type MultiSelectOptions = Static<typeof MultiSelectOptions>;

export const FileUploadOptions = RRecord({});
export type FileUploadOptions = Static<typeof FileUploadOptions>;

export const ParameterOptions = Union(
  TextOptions,
  DropdownOptions,
  SliderOptions,
  NumericOptions,
  DatetimeOptions,
  ButtonOptions,
  CheckboxOptions,
  TableOptions,
  MultiSelectOptions,
  FileUploadOptions,
);
export type ParameterOptions = Static<typeof ParameterOptions>;

export const isDynamicDataType = (
  optionsWithDefaults: ParameterOptions,
): boolean => {
  return (
    (DropdownOptions.guard(optionsWithDefaults) &&
      DynamicValueTypes.guard(optionsWithDefaults.valueOptions)) ||
    (MultiSelectOptions.guard(optionsWithDefaults) &&
      DynamicValueTypes.guard(optionsWithDefaults.multiValueOptions)) ||
    (TableOptions.guard(optionsWithDefaults) &&
      DynamicValue.guard(optionsWithDefaults.value))
  );
};

export const ParameterDisplayOptionTypeLiteral = Union(
  Literal("STRING"),
  Literal("INT"),
  Literal("FLOAT"),
  Literal("LIST"),
  Literal("BOOLEAN"),
  Literal("STRING_SELECT"),
  Literal("DATAFRAME_SELECT"),
  Literal("DYNAMIC_DEFAULT"),
);
export type ParameterDisplayOptionType = Static<
  typeof ParameterDisplayOptionTypeLiteral
>;
export const ParameterDisplayOptionType = getNormalEnum(
  ParameterDisplayOptionTypeLiteral,
);

export const ParameterDisplayOptionSelectValue = RRecord({
  value: String,
  label: String,
});
export type ParameterDisplayOptionSelectValue = Static<
  typeof ParameterDisplayOptionSelectValue
>;

export const ParameterDisplayOption = RRecord({
  name: String,
  displayName: String,
  type: ParameterDisplayOptionTypeLiteral,
}).And(
  Partial({
    tooltip: String,
    allowedValues: Array(ParameterDisplayOptionSelectValue),
  }),
);
export type ParameterDisplayOption = Static<typeof ParameterDisplayOption>;

type InputTypeToOption<T extends ParameterInputType> =
  T extends typeof ParameterInputType.DROPDOWN
    ? DropdownOptions
    : T extends typeof ParameterInputType.NUMERIC_INPUT
      ? NumericOptions
      : T extends typeof ParameterInputType.SLIDER
        ? SliderOptions
        : T extends typeof ParameterInputType.DATE
          ? DatetimeOptions
          : T extends typeof ParameterInputType.BUTTON
            ? ButtonOptions
            : T extends typeof ParameterInputType.CHECKBOX
              ? CheckboxOptions
              : T extends typeof ParameterInputType.TABLE
                ? TableOptions
                : T extends typeof ParameterInputType.MULTISELECT
                  ? MultiSelectOptions
                  : T extends typeof ParameterInputType.FILE_UPLOAD
                    ? FileUploadOptions
                    : T extends typeof ParameterInputType.TEXT_INPUT
                      ? TextOptions
                      : never;

interface ParameterInputTypeMetadata<T extends ParameterOptions> {
  allowedDataTypes: ParameterOutputType[];
  options?: ParameterDisplayOption[];
  defaultOptions: T;
}

export const ParameterInputMetadata: {
  [key in ParameterInputType]: ParameterInputTypeMetadata<
    InputTypeToOption<key>
  >;
} = {
  [ParameterInputType.TEXT_INPUT]: {
    allowedDataTypes: [ParameterOutputType.STRING],
    options: [
      {
        name: "multiline",
        displayName: "Multiline",
        type: ParameterDisplayOptionType.BOOLEAN,
      },
    ],
    defaultOptions: {
      multiline: false,
    },
  },
  [ParameterInputType.NUMERIC_INPUT]: {
    allowedDataTypes: [ParameterOutputType.NUMBER],
    options: [
      {
        name: "increment",
        displayName: "Increment size",
        type: ParameterDisplayOptionType.FLOAT,
      },
    ],
    defaultOptions: {
      increment: 1,
    },
  },
  [ParameterInputType.BUTTON]: {
    allowedDataTypes: [ParameterOutputType.BOOLEAN],
    options: [
      {
        name: "intent",
        displayName: "Style",
        type: ParameterDisplayOptionType.STRING_SELECT,
        allowedValues: [
          {
            label: "Default",
            value: "none",
          },
          {
            label: "Primary",
            value: "primary",
          },
          {
            label: "Success",
            value: "success",
          },
          {
            label: "Warning",
            value: "warning",
          },
          {
            label: "Danger",
            value: "danger",
          },
        ],
      },
      {
        name: "icon",
        displayName: "Icon",
        type: ParameterDisplayOptionType.STRING,
        tooltip:
          "List of available icons here: https://blueprintjs.com/docs/#icons/icons-list",
      },
      {
        name: "text",
        displayName: "Text",
        type: ParameterDisplayOptionType.STRING,
      },
    ],
    defaultOptions: {
      intent: "none",
      icon: "play",
      text: "Run",
    },
  },
  [ParameterInputType.CHECKBOX]: {
    allowedDataTypes: [ParameterOutputType.BOOLEAN],
    options: [
      {
        name: "style",
        displayName: "Style",
        type: ParameterDisplayOptionType.STRING_SELECT,
        allowedValues: [
          {
            label: "Checkbox",
            value: "checkbox",
          },
          {
            label: "Switch",
            value: "switch",
          },
        ],
      },
      {
        name: "text",
        displayName: "Text",
        type: ParameterDisplayOptionType.STRING,
      },
    ],
    defaultOptions: {
      style: "checkbox",
      text: "Checked",
    },
  },
  [ParameterInputType.DROPDOWN]: {
    allowedDataTypes: [ParameterOutputType.STRING, ParameterOutputType.NUMBER],
    options: [
      {
        name: "valueOptions",
        displayName: "Values",
        type: ParameterDisplayOptionType.LIST,
      },
    ],
    defaultOptions: {
      valueOptions: [],
    },
  },
  [ParameterInputType.TABLE]: {
    allowedDataTypes: [ParameterOutputType.DATA_FRAME],
    options: [
      {
        name: "value",
        displayName: "Pre-load data",
        type: ParameterDisplayOptionType.DATAFRAME_SELECT,
        tooltip:
          "Start with an empty table or pre-load table values with a dataframe",
      },
    ],
    defaultOptions: {
      value: null,
    },
  },
  [ParameterInputType.SLIDER]: {
    allowedDataTypes: [ParameterOutputType.NUMBER],
    options: [
      {
        name: "min",
        displayName: "Min",
        type: ParameterDisplayOptionType.FLOAT,
      },
      {
        name: "max",
        displayName: "Max",
        type: ParameterDisplayOptionType.FLOAT,
      },
      {
        name: "step",
        displayName: "Step size",
        type: ParameterDisplayOptionType.FLOAT,
      },
    ],
    defaultOptions: {
      min: 0,
      max: 10,
      step: 1,
    },
  },
  [ParameterInputType.DATE]: {
    allowedDataTypes: [ParameterOutputType.DATETIME],
    options: [
      {
        name: "dynamicDefault",
        displayName: "Use a variable as the default value",
        type: ParameterDisplayOptionType.DYNAMIC_DEFAULT,
      },
      {
        name: "enableTime",
        displayName: "Include time",
        type: ParameterDisplayOptionType.BOOLEAN,
      },
      {
        name: "showRelativeDates",
        displayName: "Show relative dates",
        type: ParameterDisplayOptionType.BOOLEAN,
      },
      {
        name: "testString",
        displayName: "Test string",
        type: ParameterDisplayOptionType.STRING,
      },
    ],
    defaultOptions: {
      enableTime: true,
      showRelativeDates: false,
      dynamicDefault: undefined,
      useDateRange: false,
    },
  },
  [ParameterInputType.MULTISELECT]: {
    allowedDataTypes: [
      ParameterOutputType.LIST_STRING,
      ParameterOutputType.LIST_NUMBER,
    ],
    options: [
      {
        name: "multiValueOptions",
        displayName: "Values",
        type: ParameterDisplayOptionType.LIST,
      },
    ],
    defaultOptions: {
      multiValueOptions: [],
    },
  },
  [ParameterInputType.FILE_UPLOAD]: {
    allowedDataTypes: [ParameterOutputType.DATA_FRAME],
    options: [],
    defaultOptions: {},
  },
};

export const typeToHumanString = (
  typeString: string | null | undefined,
): string => {
  if (typeString == null || typeString.length === 0) {
    return "";
  }

  let cleaned = typeString.toLowerCase().replace("_", " ");

  // handle lists separately
  if (cleaned.startsWith("list")) {
    cleaned = cleaned.split(" ").join(" of ") + "s";
  }

  return cleaned.charAt(0).toUpperCase() + cleaned.substring(1);
};
