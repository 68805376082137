import { Boolean, Dictionary, Literal, Null, Static, Union } from "runtypes";

import { getNormalEnum } from "./runtypeEnums";

const WorkspaceSetupStepLiteralValues = [
  Literal("DISPLAY_NAME"),
  Literal("INVITE_TEAMMATES"),
  /** @deprecated , but keping around for historical data */
  Literal("WORKSPACE_DATA_CONNECTION"),
  Literal("QUESTIONNAIRE"),
] as const;
export const WorkspaceSetupLiteral = Union(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- fix lint rule bug
  ...WorkspaceSetupStepLiteralValues,
);

export type WorkspaceSetupStep = Static<typeof WorkspaceSetupLiteral>;
export const WorkspaceSetupStep = getNormalEnum(WorkspaceSetupLiteral);
export const WorkspaceSetupValueType = Union(Boolean, Null);
export const WorkspaceSetupSteps = Dictionary(
  WorkspaceSetupValueType,
  WorkspaceSetupLiteral,
);
export type WorkspaceSetupSteps = Static<typeof WorkspaceSetupSteps>;

export const WorkspaceSetupStepsDefault = {
  [WorkspaceSetupStep.DISPLAY_NAME]: null,
  [WorkspaceSetupStep.INVITE_TEAMMATES]: null,
  /** @deprecated , but keping around for historical data */
  [WorkspaceSetupStep.WORKSPACE_DATA_CONNECTION]: null,
  [WorkspaceSetupStep.QUESTIONNAIRE]: null,
};

export const OrderedWorkspaceSetupSteps = [
  WorkspaceSetupStep.DISPLAY_NAME,
  WorkspaceSetupStep.QUESTIONNAIRE,
  WorkspaceSetupStep.INVITE_TEAMMATES,
];

const getWorkspaceSetupStepsOrder = (): {
  [key in WorkspaceSetupStep]: number;
} => {
  const order = {} as { [key in WorkspaceSetupStep]: number };
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  OrderedWorkspaceSetupSteps.forEach((step, idx) => (order[step] = idx + 1));
  return order;
};

export const WorkspaceSetupStepsOrder = getWorkspaceSetupStepsOrder();

export const OnboardingFlowStepLiteral = Union(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- fix lint rule bug
  ...WorkspaceSetupStepLiteralValues,
  Literal("PROJECT_TOUR_STARTED"),
  Literal("PROJECT_TOUR_LOGIC_TAB"),
  Literal("PROJECT_TOUR_DATAFRAME"),
  Literal("PROJECT_TOUR_SHARE"),
  Literal("PROJECT_TOUR_SUPPORT"),
  Literal("APP_BUILDER_TOUR_STARTED"),
  Literal("APP_BUILDER_TOUR_OUTLINE"),
  Literal("APP_BUILDER_TOUR_LAYOUT"),
  Literal("APP_BUILDER_TOUR_SETTINGS"),
  Literal("APP_BUILDER_TOUR_PUBLISH"),
);
export type OnboardingFlowStep = Static<typeof OnboardingFlowStepLiteral>;
export const OnboardingFlowStep = getNormalEnum(OnboardingFlowStepLiteral);

export const OnboardingFlowLiteral = Union(
  Literal("WORKSPACE_SETUP"),
  Literal("USER_SETUP"),
  Literal("PROJECT_TOUR"),
  Literal("APP_BUILDER_TOUR"),
);
export type OnboardingFlow = Static<typeof OnboardingFlowLiteral>;
export const OnboardingFlow = getNormalEnum(OnboardingFlowLiteral);

export const ActionTakenLiteral = Union(
  Literal("SKIPPED"),
  Literal("COMPLETED"),
);
export type ActionTaken = Static<typeof ActionTakenLiteral>;
export const ActionTaken = getNormalEnum(ActionTakenLiteral);
