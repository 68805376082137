import { Literal } from "runtypes";

// This uses typescript conditional types to extract the generic from Literal<T>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractedLiteral<T extends Literal<any>> =
  T extends Literal<infer U> ? U : never;

// Takes a runtypes Union and creates a pseudo enum object for ease of referencing in code
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getNormalEnum<T extends Literal<any>>(union: {
  alternatives: T[];
}): { [K in ExtractedLiteral<T>]: K } {
  return union.alternatives.reduce(
    (acc, val) => {
      acc[val.value as ExtractedLiteral<T>] = val.value;
      return acc;
    },
    {} as { [K in ExtractedLiteral<T>]: K },
  );
}
