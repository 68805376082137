/**
 * Attribution refers to our ability to draw a line from a third-party referral
 * to Hex to an important user-action (for example, account creation). Due to
 * some aggressive re-routing within the client, many of these props are absent
 * from URLs by the time we fire off the relevant requests.
 *
 * We retrieve and persist these attributes across redirects in a way that is
 * robust to adblockers / client limitations, ensuring the greatest retention of
 * attribution information. Tools to do so can be found here as well as in the
 * accompanying client and server utility implementations.
 *
 * If you add additional properties, please be mindful to include some light
 * context to highlights its relevance / importance for later readers.
 */
import { Record as RRecord, Static, String } from "runtypes";

export const Attribution = RRecord({
  // Google Click Identifier
  // https://support.google.com/google-ads/answer/9744275
  gclid: String.optional(),
  // Referring Hex Identifier
  // Signups can originate from tutorials, so we track the originating hexId.
  rhid: String.optional(),
  // UTM parameters
  utm_medium: String.optional(),
  utm_name: String.optional(),
  utm_source: String.optional(),
});
export type Attribution = Static<typeof Attribution>;

export const NoAttribution: Attribution = {};

const throwaway = "https://app.hex.tech";

export const withAttribution = (
  url: string,
  attribution: Attribution,
): string => {
  const { gclid, rhid, utm_medium, utm_name, utm_source } = attribution;
  if (
    gclid == null &&
    rhid == null &&
    utm_medium == null &&
    utm_name == null &&
    utm_source == null
  ) {
    return url;
  }

  /**
   * Passing relative paths without a base to URL fails. To sidestep this, we
   * prepend and later remove a throwaway portion.
   *
   * There continues to be conversation about this and related issues:
   *   https://github.com/whatwg/url/issues/531
   *   https://github.com/nodejs/node/issues/12682
   *   https://github.com/whatwg/url/issues/136
   */
  if (url.startsWith("/")) {
    const parsed = new URL(url, throwaway);
    if (gclid != null) {
      parsed.searchParams.set("gclid", gclid);
    }
    if (rhid != null) {
      parsed.searchParams.set("rhid", rhid);
    }
    if (utm_medium != null) {
      parsed.searchParams.set("utm_medium", utm_medium);
    }
    if (utm_name != null) {
      parsed.searchParams.set("utm_name", utm_name);
    }
    if (utm_source != null) {
      parsed.searchParams.set("utm_source", utm_source);
    }
    return parsed.toString().slice(throwaway.length);
  }

  const parsed = new URL(url);
  if (gclid != null) {
    parsed.searchParams.set("gclid", gclid);
  }
  if (rhid != null) {
    parsed.searchParams.set("rhid", rhid);
  }
  if (utm_medium != null) {
    parsed.searchParams.set("utm_medium", utm_medium);
  }
  if (utm_name != null) {
    parsed.searchParams.set("utm_name", utm_name);
  }
  if (utm_source != null) {
    parsed.searchParams.set("utm_source", utm_source);
  }
  return parsed.toString();
};
