export function stableStringify(
  obj: object | readonly object[] | null | undefined = {},
  pretty: boolean = false,
): string {
  const allKeys = new Set<string>();
  // traverse object and get all existing keys
  JSON.stringify(obj, (key, value) => {
    allKeys.add(key);
    return value;
  });
  // sort those keys and use those to deterministically stringify json

  return JSON.stringify(
    obj,
    Array.from(allKeys).sort(),
    pretty ? 2 : undefined,
  );
}
