import type { Field } from "vega-lite/build/src/channeldef";
import type { Mark, MarkDef } from "vega-lite/build/src/mark";
import type { UnitSpec } from "vega-lite/build/src/spec/unit";

import { VegaLayer, VegaLiteSpec, VegaMetadata } from "./types";

export function newDefaultMarkSpec(type: Mark = "line"): MarkDef {
  return {
    tooltip: true,
    type,
    clip: true,
  };
}

export function newDefaultTopLevelLayerSpec(): VegaLiteSpec {
  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    // first layer defaults to bar chart, subsequent layers default to line charts
    layer: [newDefaultLayerSpec("bar")],
    resolve: { scale: {} },
    // fake data so that the spec can pass validation
    // this gets overridden server side when rendering
    datasets: { layer00: [{ name: "dummy", value: 0 }] },
  };
}

export function newDefaultLayerSpec(type: Mark = "line"): UnitSpec<Field> {
  return {
    data: { name: "layer00" },
    mark: newDefaultMarkSpec(type),
    encoding: {},
  };
}

export function newDefaultMetadata(): VegaMetadata {
  return {
    byLayer: [newDefaultVegaLayer()],
  };
}

export function newDefaultVegaLayer(): VegaLayer {
  return {
    selectedDataFrameVariableName: null,
  };
}
