import { sentenceCase } from "../sentenceCase";

export function getFirstName(name: string | null | undefined): string {
  return sentenceCase(name?.split(" ")[0]) ?? "";
}

export function leadingWhitespaceLength(line: string): number {
  return line.match(/^\s*/)?.[0].length ?? 0;
}

/**
 * Removes leading whitespace common to each line of {@param lines}.
 */
export function trimLeadingWhitespace(lines: string): string {
  const linesArr = lines.split("\n");
  const dedentAmount =
    linesArr.length && Math.min(...linesArr.map(leadingWhitespaceLength));
  if (dedentAmount > 0) {
    return linesArr.map((line) => line.slice(dedentAmount)).join("\n");
  } else {
    return lines;
  }
}

/** Trims whitespace and checks if there is a value, sets null if not. */
export function cleanString(str: string): string | null {
  const stringCleaned = str.trim();
  return stringCleaned.length === 0 ? null : stringCleaned;
}

export function indent(
  str: string,
  amount: number,
  character: string = " ",
): string {
  const prefix = character.repeat(amount);
  return str
    .split("\n")
    .map((line) => prefix + line)
    .join("\n");
}

/** Converts @param input to Title Case where appropriate, switching separators from `_` for ` ` in the process
 *  Multiple spaces / underscores are replaced with single characters
 */
const titleCaseExceptions: { [key: string]: string } = {
  Id: "ID",
};
export function titleCase(input: string): string {
  if (input === "") {
    return input;
  }

  const withSpaces = input.replace(/_/g, " ");

  return withSpaces
    .split(" ")
    .filter((word) => word.length > 0)
    .map((word) => {
      return word[0]?.toUpperCase() + word.toLowerCase().slice(1);
    })
    .map((word) => {
      if (word in titleCaseExceptions) {
        return titleCaseExceptions[word];
      }
      return word;
    })
    .join(" ");
}

export function concatRespectingCase(
  to: string,
  text: string,
  type: "prepend" | "append" = "append",
): string {
  const isUpperCase = to === to.toUpperCase();
  text = isUpperCase ? text.toUpperCase() : text.toLowerCase();
  if (type === "prepend") {
    return `${text}${to}`;
  } else {
    return `${to}${text}`;
  }
}
