import {
  Array,
  Boolean,
  Lazy,
  Literal,
  Null,
  Number,
  Optional,
  Record,
  Runtype,
  Static,
  String,
  Union,
} from "runtypes";

import { HexTypeLiteral } from "./enums.js";
import {
  DataConnectionId,
  DataSourceTableId,
  GroupId,
  HexId,
  UserId,
} from "./idTypeBrands";
import { MentionedDataframeName } from "./MagicTypes";

export const RichTextMarkBold = Literal("bold");
export const RichTextMarkItalic = Literal("italic");
export const RichTextMarkUnderline = Literal("underline");
export const RichTextMarkStrikethrough = Literal("strikethrough");
export const RichTextMarkCode = Literal("code");
export const RichTextMarkSelection = Literal("selection");
export type RichTextMarkBold = Static<typeof RichTextMarkBold>;
export type RichTextMarkItalic = Static<typeof RichTextMarkItalic>;
export type RichTextMarkUnderline = Static<typeof RichTextMarkUnderline>;
export type RichTextMarkStrikethrough = Static<
  typeof RichTextMarkStrikethrough
>;
export type RichTextMarkCode = Static<typeof RichTextMarkCode>;
export type RichTextMarkSelection = Static<typeof RichTextMarkSelection>;

export const RichTextMark = Union(
  RichTextMarkBold,
  RichTextMarkItalic,
  RichTextMarkUnderline,
  RichTextMarkStrikethrough,
  RichTextMarkCode,
);
export type RichTextMark = Static<typeof RichTextMark>;

export const RichTextP = Literal("p");
/**
 * @deprecated use RichTextP ("p") type instead.
 */
export const RichTextPLegacy = Literal("paragraph");
export const RichTextH1 = Literal("h1");
export const RichTextH2 = Literal("h2");
export const RichTextH3 = Literal("h3");
export const RichTextH4 = Literal("h4");
export const RichTextH5 = Literal("h5");
/**
 * @deprecated use RichTextH5 ("h5") type instead.
 */
export const RichTextH6 = Literal("h6");
export const RichTextBlockquote = Literal("blockquote");

export type RichTextHeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export const RichTextParagraph = Union(RichTextP, RichTextPLegacy);
export type RichTextParagraph = Static<typeof RichTextParagraph>;

export const RichTextCodeblock = Literal("codeblock");
export type RichTextCodeblock = Static<typeof RichTextCodeblock>;

export const RichTextHeading = Union(
  RichTextH1,
  RichTextH2,
  RichTextH3,
  RichTextH4,
  RichTextH5,
  RichTextH6,
);
export type RichTextHeading = Static<typeof RichTextHeading>;

export const RichTextOL = Literal("ol");
export const RichTextUL = Literal("ul");
export const RichTextLI = Literal("li");
export const RichTextLIC = Literal("lic"); // list item content
export type RichTextOL = Static<typeof RichTextOL>;
export type RichTextUL = Static<typeof RichTextUL>;
export type RichTextLI = Static<typeof RichTextLI>;
export type RichTextLIC = Static<typeof RichTextLIC>;

export const RichTextLink = Literal("a");
export type RichTextLink = Static<typeof RichTextLink>;

export const RichTextReference = Literal("hex_reference");
export type RichTextReference = Static<typeof RichTextReference>;

export const RichTextImage = Literal("image");
export type RichTextImage = Static<typeof RichTextImage>;

export const RichTextTextType = Union(
  RichTextParagraph,
  RichTextHeading,
  RichTextBlockquote,
  RichTextCodeblock,
);
export type RichTextTextType = Static<typeof RichTextTextType>;

export const RichTextListType = Union(RichTextOL, RichTextUL);
export type RichTextListType = Static<typeof RichTextListType>;

export const RichTextElementType = Union(
  RichTextTextType,
  RichTextListType,
  RichTextLI,
);
export type RichTextElementType = Static<typeof RichTextElementType>;

export const RichTextMentionElementType = Literal("mention");
export type RichTextMentionElementType = Static<
  typeof RichTextMentionElementType
>;

export const RichTextMentionInputElementType = Literal("mention_input");
export type RichTextMentionInputElementType = Static<
  typeof RichTextMentionInputElementType
>;

export const MentionTriggerType = Literal("@");
export type MentionTriggerType = Static<typeof MentionTriggerType>;

export const FormattedText = Record({
  text: String,
  [RichTextMarkBold.value]: Optional(Boolean),
  [RichTextMarkItalic.value]: Optional(Boolean),
  [RichTextMarkUnderline.value]: Optional(Boolean),
  [RichTextMarkStrikethrough.value]: Optional(Boolean),
  [RichTextMarkCode.value]: Optional(Boolean),
  [RichTextMarkSelection.value]: Optional(Boolean),
  /**
   * Legacy editor used strikeThrough, but Plate uses strikethrough.
   * We can make plate use strikeThrough but given that the industry standard
   * for this is actually strikethrough, switch to that while maintaining backcompat
   * @deprecated use strikethrough instead
   */
  strikeThrough: Optional(Boolean), // legacy
});
export type FormattedText = Static<typeof FormattedText>;

export const UserMention = Record({
  type: RichTextMentionElementType,
  // this is used for serialization and while initially
  // loading. Should prefer loading the actual value by id.
  previewText: String,
  children: Array(FormattedText),
  mentionType: Literal("user"),
  userId: UserId,
  id: String,
});

export const GroupMention = Record({
  type: RichTextMentionElementType,
  // this is used for serialization and while initially
  // loading. Should prefer loading the actual value by id.
  previewText: String,
  children: Array(FormattedText),
  mentionType: Literal("group"),
  groupId: GroupId,
  id: String,
});

export const TableMention = Record({
  type: RichTextMentionElementType,
  // this is used for serialization and while initially
  // loading. Should prefer loading the actual value by id.
  previewText: String,
  children: Array(FormattedText),
  mentionType: Literal("table"),
  table: String,
  schema: String,
  database: String.Or(Null),
  id: String,
  tableId: DataSourceTableId,
  connectionId: DataConnectionId,
});

export const DataframeMention = Record({
  type: RichTextMentionElementType,
  previewText: String,
  children: Array(FormattedText),
  mentionType: Literal("dataframe"),
  name: MentionedDataframeName,
  id: String,
  connectionId: DataConnectionId.Or(Null),
});

export const HexMention = Record({
  type: RichTextMentionElementType,
  // this is used for serialization and while initially
  // loading. Should prefer loading the actual value by id.
  previewText: String,
  children: Array(FormattedText),
  mentionType: Literal("hex"),
  id: String,
  hexId: HexId,
  hexType: HexTypeLiteral,
});

export const HexMentionInput = Record({
  type: RichTextMentionInputElementType,
  children: Array(FormattedText),
});

export type GroupMention = Static<typeof GroupMention>;
export type UserMention = Static<typeof UserMention>;
export type TableMention = Static<typeof TableMention>;
export type DataframeMention = Static<typeof DataframeMention>;
export type HexMention = Static<typeof HexMention>;
export type HexMentionInput = Static<typeof HexMentionInput>;

export const MentionElement = Union(
  UserMention,
  GroupMention,
  TableMention,
  DataframeMention,
  HexMention,
);
export type MentionElement = Static<typeof MentionElement>;

export const LinkElement = Record({
  type: RichTextLink,
  url: String,
  children: Array(FormattedText),
});
export type LinkElement = Static<typeof LinkElement>;

export const ReferenceElement = Record({
  type: RichTextReference,
  id: String,
  value: String,
  children: Array(FormattedText),
});
export type ReferenceElement = Static<typeof ReferenceElement>;

export const ImageAlignment = Union(
  Literal("left"),
  Literal("center"),
  Literal("right"),
);
export type ImageAlignment = Static<typeof ImageAlignment>;

export const ImageElement = Record({
  type: RichTextImage,
  src: String,
  width: Optional(Number),
  alignment: Optional(ImageAlignment),
  children: Array(FormattedText),
});
export type ImageElement = Static<typeof ImageElement>;

export const InlineElement = Union(
  FormattedText,
  MentionElement,
  LinkElement,
  ReferenceElement,
  ImageElement,
);
export type InlineElement = Static<typeof InlineElement>;

export const TextElement = Record({
  type: RichTextTextType,
  children: Array(InlineElement),
  indent: Optional(Number),
});
export type TextElement = Static<typeof TextElement>;

export const ListItemContentElement = Record({
  type: RichTextLIC,
  children: Array(InlineElement),
});
export type ListItemContentElement = Static<typeof ListItemContentElement>;

export const ListItemElement: Runtype<{
  type: RichTextLI;
  children: (ListItemContentElement | ListElement)[];
}> = Lazy(() =>
  Record({
    type: RichTextLI,
    children: Array(Union(ListItemContentElement, ListElement)),
  }),
);
export type ListItemElement = Static<typeof ListItemElement>;

export const ListElement = Record({
  type: RichTextListType,
  children: Array(ListItemElement),
});
export type ListElement = Static<typeof ListElement>;

export const RichTextHR = Literal("hr");
export type RichTextHR = Static<typeof RichTextHR>;

export const HRElement = Record({
  type: RichTextHR,
  children: Array(FormattedText),
});
export type HRElement = Static<typeof HRElement>;

export const CustomElement = Union(
  TextElement,
  MentionElement,
  ListElement,
  ListItemElement,
  ListItemContentElement,
  LinkElement,
  ReferenceElement,
  HRElement,
  ImageElement,
  HexMentionInput,
);
export type CustomElement = Static<typeof CustomElement>;

export const Descendant = Union(CustomElement, FormattedText);
export type Descendant = Static<typeof Descendant>;

export const RichTextDocument = Array(CustomElement);
export type RichTextDocument = Static<typeof RichTextDocument>;

export type RichTextComputedReferences = {
  [referenceElementId: string]: string;
};
