import { Descendant, Element as SlateElement, Text as SlateText } from "slate";

import { nullthrows } from "./assertions";
import { guardNever } from "./errors";
import { GroupId, HexId, UserId } from "./idTypeBrands";
import {
  HexMentionInput,
  ImageElement,
  LinkElement,
  ListElement,
  ListItemElement,
  MentionElement,
  ReferenceElement,
  RichTextBlockquote,
  RichTextCodeblock,
  RichTextComputedReferences,
  RichTextDocument,
  RichTextH1,
  RichTextH2,
  RichTextH3,
  RichTextH4,
  RichTextH5,
  RichTextH6,
  RichTextHR,
  RichTextHeading,
  RichTextLI,
  RichTextLIC,
  RichTextListType,
  RichTextOL,
  RichTextParagraph,
  RichTextUL,
  TableMention,
  TextElement,
} from "./richTextTypes";
import { stableStringify } from "./stableStringify";

// serialize json deterministically to prevent issues with reference locators
export function serializeRichText(document: RichTextDocument): string {
  return stableStringify(document);
}

export function deserializeRichText(
  serializedDocument: string,
): RichTextDocument {
  return JSON.parse(serializedDocument);
}

/**
 * Very basic converter for converting plain text to rich text. Does not
 * support anything beyond basic text.
 */
export function convertPlainTextToRichText(
  plainText: string,
): RichTextDocument {
  // Split the text into lines to create separate paragraph blocks
  const lines = plainText.split("\n");

  // Map each line to a Slate-compatible text block
  const slateBlocks = lines.map((line) => ({
    type: "paragraph",
    children: [{ text: line }],
  }));

  const richText = RichTextDocument.check(slateBlocks);

  return richText;
}

export function convertRichTextToPlainText(
  document: RichTextDocument,
  cr: RichTextComputedReferences = {},
): string {
  return document.map((n) => convertDescendantToPlaintext(n, cr)).join("");
}

function convertDescendantToPlaintext(
  descendant: Descendant,
  cr: RichTextComputedReferences,
): string {
  if (SlateText.isText(descendant)) {
    return descendant.text;
  } else if (TableMention.guard(descendant)) {
    // Always add some whitespace after a table mention. This way when we split magic prompts
    // on whitespace we don't include extra, non-mention text in the prompt
    return `@${descendant.previewText} `;
  } else if (HexMentionInput.guard(descendant)) {
    return `@${descendant.children.flatMap((val) => val.text).join("")} `;
  } else if (descendant.type === "mention") {
    return `@${descendant.previewText}`;
  } else if (
    descendant.type === RichTextUL.value ||
    descendant.type === RichTextOL.value
  ) {
    return convertListToMarkdown(descendant, 0, cr).concat("\n");
  } else if (RichTextBlockquote.guard(descendant.type)) {
    return descendant.children
      .map((n) => `> ${convertDescendantToPlaintext(n, cr)}`)
      .join("")
      .concat("\n\n");
  } else if (
    RichTextLIC.guard(descendant.type) ||
    RichTextLI.guard(descendant.type)
  ) {
    return ""; // lic and li are handled within the list
  } else if (LinkElement.guard(descendant)) {
    return descendant.children
      .map((n) => convertDescendantToPlaintext(n, cr))
      .join("");
  } else if (ReferenceElement.guard(descendant)) {
    const computedValue = cr[descendant.id];
    return computedValue ?? descendant.value;
  } else if (RichTextHeading.guard(descendant.type)) {
    return descendant.children
      .map((n) => convertDescendantToPlaintext(n, cr))
      .join("")
      .concat("\n\n");
  } else if (RichTextParagraph.guard(descendant.type)) {
    return descendant.children
      .map((n) => convertDescendantToPlaintext(n, cr))
      .join("")
      .concat("\n\n");
  } else if (RichTextHR.guard(descendant.type)) {
    return "---\n\n";
  } else if (ImageElement.guard(descendant)) {
    return `[Image: ${descendant.src}]`;
  } else if (RichTextCodeblock.guard(descendant.type)) {
    const contents = descendant.children
      .map((n) => convertDescendantToPlaintext(n, cr))
      .join("");
    return `\`\`\`\n${contents}\n\n\`\`\`\n`;
  } else {
    guardNever(descendant.type, "");
    return "";
  }
}

export function convertRichTextToMarkdown(
  document: RichTextDocument,
  cr: RichTextComputedReferences = {},
): string {
  return document.map((node) => convertDescendantToMarkdown(node, cr)).join("");
}

function convertDescendantToMarkdown(
  descendant: Descendant,
  cr: RichTextComputedReferences,
): string {
  if (SlateText.isText(descendant)) {
    let value = descendant.text;
    if (descendant.bold === true) {
      value = `**${value}**`;
    }
    if (descendant.code === true) {
      value = `\`${value}\``;
    }
    if (descendant.italic === true) {
      value = `_${value}_`;
    }
    if (descendant.strikethrough ?? descendant.strikeThrough === true) {
      value = `~~${value}~~`;
    }
    if (descendant.underline === true) {
      value = `__${value}__`;
    }
    return value;
  } else if (MentionElement.guard(descendant)) {
    return `@${descendant.previewText}`;
  } else if (HexMentionInput.guard(descendant)) {
    return `@${descendant.children.flatMap((val) => val.text).join("")}`;
  } else if (
    descendant.type === RichTextUL.value ||
    descendant.type === RichTextOL.value
  ) {
    return convertListToMarkdown(descendant, 0, cr).concat("\n\n");
  } else if (RichTextBlockquote.guard(descendant.type)) {
    return descendant.children
      .map((n) => `> ${convertDescendantToMarkdown(n, cr)}`) // add four spaces as blockquote indent
      .join("")
      .concat("\n\n");
  } else if (
    RichTextLIC.guard(descendant.type) ||
    RichTextLI.guard(descendant.type)
  ) {
    return ""; // lic and li are handled within the list
  } else if (LinkElement.guard(descendant)) {
    const url = descendant.url;
    const text = descendant.children[0]?.text ?? "";
    return `[${text}](${url})`;
  } else if (ReferenceElement.guard(descendant)) {
    const computedValue = cr[descendant.id];
    return computedValue ?? descendant.value;
  } else if (RichTextHeading.guard(descendant.type)) {
    const markdown = descendant.children
      .map((n) => convertDescendantToMarkdown(n, cr))
      .join("")
      .concat("\n\n");
    if (RichTextH1.guard(descendant.type)) {
      return `# ${markdown}`;
    } else if (RichTextH2.guard(descendant.type)) {
      return `## ${markdown}`;
    } else if (RichTextH3.guard(descendant.type)) {
      return `### ${markdown}`;
    } else if (RichTextH4.guard(descendant.type)) {
      return `#### ${markdown}`;
    } else if (
      RichTextH5.guard(descendant.type) ||
      RichTextH6.guard(descendant.type)
    ) {
      return `##### ${markdown}`;
    } else {
      guardNever(descendant.type, "");
      return markdown;
    }
  } else if (RichTextParagraph.guard(descendant.type)) {
    return descendant.children
      .map((n) => convertDescendantToMarkdown(n, cr))
      .join("")
      .concat("\n\n");
  } else if (RichTextHR.guard(descendant.type)) {
    return "---\n\n";
  } else if (ImageElement.guard(descendant)) {
    const { src, width } = descendant;
    return `<img src="${src}" width="${width ?? ""}" />\n`;
  } else if (RichTextCodeblock.guard(descendant.type)) {
    const contents = descendant.children
      .map((n) => convertDescendantToPlaintext(n, cr))
      .join("");
    return `\`\`\`\n${contents}\n\n\`\`\`\n`;
  } else {
    guardNever(descendant.type, "");
    return "";
  }
}

function convertListToMarkdown(
  list: ListElement,
  indentSpaces: number,
  computedReferences: RichTextComputedReferences,
): string {
  return list.children
    .map((li, index) =>
      convertListItemToMarkdown({
        item: li,
        type: list.type,
        indentSpaces: indentSpaces,
        index: index + 1 /* 1-indexed */,
        computedReferences,
      }),
    )
    .join("\n");
}

const LIST_INDENT_SIZE = 2;

function convertListItemToMarkdown({
  computedReferences,
  indentSpaces,
  index,
  item,
  type,
}: {
  item: ListItemElement;
  type: RichTextListType;
  indentSpaces: number;
  index: number;
  computedReferences: RichTextComputedReferences;
}): string {
  return item.children
    .map((child) => {
      switch (child.type) {
        case RichTextOL.value:
        case RichTextUL.value:
          return convertListToMarkdown(
            child,
            indentSpaces + LIST_INDENT_SIZE,
            computedReferences,
          );
        case RichTextLIC.value: {
          const listStyle = type === RichTextOL.value ? `${index}.` : "-";
          const indent = Array(indentSpaces).fill(" ").join("");
          return `${indent}${listStyle} ${child.children.map((c) =>
            convertDescendantToMarkdown(c, computedReferences),
          )}`;
        }
        default:
          guardNever(child, "Unexpected list item type");
          return "";
      }
    })
    .join("\n");
}

export function convertRichTextFromPlainText(text: string): RichTextDocument {
  const doc: (ListElement | TextElement)[] = [];
  let lists = [];
  for (const line of text.split("\n")) {
    const orderedListMatch = /^(\s*)\d+(\.|\))\s(.+)$/.exec(line);
    const unorderedListMatch = /^(\s*)(-|\*)\s(.+)$/.exec(line);
    const isOrderedList = orderedListMatch != null;
    const isUnorderedList = unorderedListMatch != null;

    const blockquoteMatch = /^>\s(.+)$/.exec(line);
    const isBlockquote = blockquoteMatch != null;
    if (isBlockquote) {
      doc.push({
        type: "blockquote",
        children: [
          {
            text: nullthrows(blockquoteMatch?.[1]),
          },
        ],
      });
      continue;
    }

    if (isUnorderedList || isOrderedList) {
      const indent = orderedListMatch?.[1] ?? unorderedListMatch?.[1] ?? "";
      const newListNestingCount = indent.length / LIST_INDENT_SIZE;
      if (newListNestingCount > lists.length - 1) {
        const newList: ListElement = {
          type: isOrderedList ? RichTextOL.value : RichTextUL.value,
          children: [],
        };

        if (lists.length > 0) {
          const currentList = lists[lists.length - 1];
          const listItem = currentList?.children[0];
          listItem?.children.push(newList);
        } else {
          doc.push(newList);
        }
        lists.push(newList);
      }
      // match lists to current
      // lists = [l1, l2], new list nest = 1 -> lists = [l1, l2]
      // lists = [l1, l2], new list nest = 0 -> lists = [l1]
      // lists = [l1, l2, l3, l4], new list nest = 1 -> lists = [l1, l2]
      while (newListNestingCount < lists.length - 1) {
        lists.pop();
      }

      const list = lists[lists.length - 1];
      if (
        list != null &&
        (list.type === RichTextUL.value || list.type === RichTextOL.value)
      ) {
        list.children.push({
          type: "li",
          children: [
            {
              type: "lic",
              children: [
                {
                  text: orderedListMatch?.[3] ?? unorderedListMatch?.[3] ?? "",
                },
              ],
            },
          ],
        });
        continue;
      }
    }

    if (lists.length > 0) {
      lists = [];
    }

    doc.push({
      type: "paragraph",
      children: [
        {
          text: line,
        },
      ],
    });
  }
  return doc;
}

export function getMentionedEntityIds(document: RichTextDocument): {
  userIds: readonly UserId[];
  groupIds: readonly GroupId[];
  hexIds: readonly HexId[];
} {
  return getMentionElements(document).reduce(
    (
      acc: {
        userIds: UserId[];
        groupIds: GroupId[];
        hexIds: HexId[];
      },
      e,
    ) => {
      if (e.mentionType === "group") {
        acc.groupIds.push(e.groupId);
      } else if (e.mentionType === "user") {
        acc.userIds.push(e.userId);
      } else if (e.mentionType === "hex") {
        acc.hexIds.push(e.hexId);
      }
      return acc;
    },
    {
      userIds: [],
      groupIds: [],
      hexIds: [],
    },
  );
}

export function getMentionElements(
  document: RichTextDocument,
): readonly MentionElement[] {
  return document.flatMap((d) => getMentionElementsFromDescendant(d));
}

function getMentionElementsFromDescendant(
  descendant: Descendant,
): readonly MentionElement[] {
  if (SlateText.isText(descendant)) {
    return [];
  } else if (
    SlateElement.isElement(descendant) &&
    descendant.type === "mention"
  ) {
    return [descendant];
  } else {
    return descendant.children.flatMap((n) =>
      getMentionElementsFromDescendant(n),
    );
  }
}

export function isRichTextFromEmptyString(document: RichTextDocument): boolean {
  return convertRichTextToPlainText(document).trim().length === 0;
}
