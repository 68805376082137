import { isArray } from "lodash";
import { Field } from "vega-lite/build/src/channeldef";
import { LayerSpec, UnitSpec } from "vega-lite/build/src/spec";

import {
  VegaLayer,
  VegaLiteLayerOrFacetSpec,
  VegaLiteSpec,
  VegaMetadata,
} from "./types";

export function hasEncodingChannels(spec: VegaLiteSpec): boolean {
  const encodingChannelsMerged = Object.assign(
    {},
    ...(spec?.layer?.map((l) => l.encoding) ?? []),
  );
  return (
    "x" in encodingChannelsMerged ||
    "y" in encodingChannelsMerged ||
    "theta" in encodingChannelsMerged
  );
}

export const getSelectedDataFrameVariableName = (
  metadata: VegaMetadata | null,
  selectedLayerIndex: number,
): string | null =>
  metadata?.byLayer[selectedLayerIndex]?.selectedDataFrameVariableName ?? null;

export const getVariableNameList = (
  metadata: VegaMetadata | null,
): (string | null)[] =>
  metadata?.byLayer.map((layer) => layer.selectedDataFrameVariableName) ?? [];

export const getPrunedVariableNameList = (
  metadata: VegaMetadata | null,
): string[] =>
  metadata?.byLayer.reduce<string[]>(
    (list: string[], layer: VegaLayer): string[] => {
      const { selectedDataFrameVariableName: variableName } = layer;
      if (variableName != null && !list.includes(variableName)) {
        list.push(variableName);
      }
      return list;
    },
    [],
  ) ?? [];

export const getChartId = (metadata: VegaMetadata | null): string | null => {
  const variableNameList = getPrunedVariableNameList(metadata);
  return variableNameList.length !== 0 ? variableNameList.join("_") : null;
};

export const getLayerKey = (index: number): string =>
  `layer${`${index}`.padStart(2, "0")}`;

export const getLayerNameByVariableName = (
  metadata: VegaMetadata | null,
): Record<string, string> => {
  return Array.from((metadata?.byLayer ?? []).entries()).reduce<
    Record<string, string>
  >((acc, tuple) => {
    const [index, { selectedDataFrameVariableName: name }] = tuple;
    if (name == null || acc[name] != null) {
      return acc;
    }
    return {
      ...acc,
      [name]: getLayerKey(index),
    };
  }, {});
};

/**
 * Handle populating layers of spec with deduped references to data. Datasets
 * themselves are dummy and must be later populated with real rows.
 */
export const getWithDatasetReferences = ({
  layerNameByVariableName: layerNameByVariableName_,
  metadata,
  vegaLiteSpec,
}: {
  vegaLiteSpec: VegaLiteSpec;
  metadata: VegaMetadata | null;
  layerNameByVariableName?: Record<string, string>;
}): VegaLiteSpec => {
  const layerNameByVariableName =
    layerNameByVariableName_ ?? getLayerNameByVariableName(metadata);
  return {
    ...vegaLiteSpec,
    layer: Array.from((vegaLiteSpec.layer ?? []).entries()).map(
      ([index, layer]) => {
        const layerMetadata = metadata?.byLayer[index];
        const { data: _, ...layerWithoutData } = layer;
        const encoding = layer.encoding ?? {};
        if (
          layerMetadata == null ||
          layerMetadata.selectedDataFrameVariableName == null ||
          !("x" in encoding || "y" in encoding || "theta" in encoding)
        ) {
          return layerWithoutData;
        }

        const name = layerMetadata.selectedDataFrameVariableName;
        const layerName = layerNameByVariableName[name];
        if (layerName == null) {
          return layerWithoutData;
        } else {
          return {
            ...layer,
            data: { name: layerName },
          };
        }
      },
    ),
    datasets: Object.values(layerNameByVariableName).reduce(
      (acc, variableName) => ({
        ...acc,
        [variableName]: [
          {
            name: "dummy",
            value: 0,
          },
        ],
      }),
      {},
    ),
  };
};

/**
 * Get a new VegaLite spec where we inject the dataframe name associated with a layer
 * (as indicated by `metadata`) into the spec's `data.name` property.
 * We also remove the top-level `datasets` field from the spec if it exists.
 * This appropriately transforms the spec we use for chart cells into a spec
 * that is good for the `HexChart()` API.
 */
export const getWithDataReferences = ({
  metadata,
  vegaLiteSpec,
}: {
  vegaLiteSpec: VegaLiteLayerOrFacetSpec;
  metadata: VegaMetadata | null;
}): VegaLiteLayerOrFacetSpec => {
  let spec: VegaLiteLayerOrFacetSpec;

  function processLayer([index, layer]: [
    number,
    LayerSpec<Field> | UnitSpec<Field>,
  ]): LayerSpec<Field> | UnitSpec<Field> {
    const layerMetadata = metadata?.byLayer[index];
    const { data: _, ...layerWithoutData } = layer;
    if (
      layerMetadata == null ||
      layerMetadata.selectedDataFrameVariableName == null ||
      !layerHasValidEncoding(layerWithoutData)
    ) {
      return layerWithoutData;
    }

    const dataframeName = layerMetadata.selectedDataFrameVariableName;
    return {
      ...layer,
      data: { name: dataframeName },
    };
  }

  if ("spec" in vegaLiteSpec) {
    // GenericFacetSpec
    spec = {
      ...vegaLiteSpec,
      spec: {
        ...vegaLiteSpec.spec,
        layer:
          "layer" in vegaLiteSpec.spec
            ? Array.from(vegaLiteSpec.spec.layer.entries()).map(processLayer)
            : [],
      },
    };
  } else {
    // LayerSpec
    spec = {
      ...vegaLiteSpec,
      layer: Array.from(vegaLiteSpec.layer.entries()).map(processLayer),
    };
  }

  // `datasets` wil be populated by `HexChart()`, so we don't need it at all right now
  delete spec["datasets"];

  return spec;
};

function layerHasValidEncoding(
  layer: LayerSpec<Field> | UnitSpec<Field>,
): boolean {
  const encoding = layer.encoding ?? {};
  if ("x" in encoding || "y" in encoding || "theta" in encoding) {
    return true;
  }

  if ("layer" in layer && layer.layer != null && isArray(layer.layer)) {
    return layer.layer.some(layerHasValidEncoding);
  }
  return false;
}
