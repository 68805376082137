import { Literal, Static, Union } from "runtypes";

import { getNormalEnum } from "./runtypeEnums.js";

const PROJECT_TEMPLATES_FOR_UI = [
  Literal("DATA_APP"),
  Literal("AD_HOC"),
  Literal("CHAINED_SQL"),
] as const;

/** Project templates that are surfaced in the template panel as ones for a user to choose */
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- array spread is fine
export const ProjectTemplateForUILiteral = Union(...PROJECT_TEMPLATES_FOR_UI);
export const ProjectTemplateForUI = getNormalEnum(ProjectTemplateForUILiteral);
export type ProjectTemplateForUI = Static<typeof ProjectTemplateForUILiteral>;

/** All possible project templates, including ones that are only used behind-the-scenes */
export const ProjectTemplateLiteral = Union(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- array spread is fine
  ...PROJECT_TEMPLATES_FOR_UI,
  Literal("SQL_AND_PYTHON_TOUR"),
  Literal("SQL_ONLY_TOUR"),
  Literal("NO_CODE_TOUR"),
);
export const ProjectTemplate = getNormalEnum(ProjectTemplateLiteral);
export type ProjectTemplate = Static<typeof ProjectTemplateLiteral>;
