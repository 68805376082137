import {
  Array,
  Boolean,
  Dictionary,
  Literal,
  Null,
  Number,
  Optional,
  Record,
  Static,
  String,
  Undefined,
  Union,
} from "runtypes";

import { getNormalEnum } from "./runtypeEnums";

export const ScopeItemTypeLiteral = Union(
  Literal("MODULE"),
  Literal("FUNCTION"),
  Literal("CLASS"),
  Literal("ARRAY"),
  Literal("DATAFRAME"),
  Literal("REMOTE_DATAFRAME"),
  Literal("BIGFRAMES_DATAFRAME"),
  Literal("SNOWPARK_DATAFRAME"),
  Literal("PIVOT_TABLE"),
  Literal("STRING"),
  Literal("NUMBER"),
  Literal("BOOLEAN"),
  Literal("DICTIONARY"),
  Literal("DATE"),
  Literal("DATETIME"),
  Literal("UNKNOWN"),
  Literal("DB_TABLE"),
  // This is deprecated, but kept around for backwards compatability. Scope
  // items that are unable to be parsed will now have type UNKNOWN
  Literal("ERROR"),
  Literal("TBD"),
  // SUP-571: we write this tombstone value instead of deleting to avoid multiplayer versioning issues
  Literal("DELETED"),
);

export type ScopeItemType = Static<typeof ScopeItemTypeLiteral>;
export const ScopeItemType = getNormalEnum(ScopeItemTypeLiteral);

const HUMAN_STRING_BY_SCOPE_TYPE: { [key in ScopeItemType]: string } = {
  [ScopeItemType.MODULE]: "module",
  [ScopeItemType.FUNCTION]: "function",
  [ScopeItemType.CLASS]: "class",
  [ScopeItemType.ARRAY]: "list",
  [ScopeItemType.DATAFRAME]: "dataframe",
  [ScopeItemType.REMOTE_DATAFRAME]: "query",
  [ScopeItemType.BIGFRAMES_DATAFRAME]: "BigQuery dataframe",
  [ScopeItemType.SNOWPARK_DATAFRAME]: "Snowpark dataframe",
  [ScopeItemType.PIVOT_TABLE]: "Pivot table",
  [ScopeItemType.STRING]: "string",
  [ScopeItemType.NUMBER]: "number",
  [ScopeItemType.BOOLEAN]: "boolean",
  [ScopeItemType.DICTIONARY]: "dict",
  [ScopeItemType.DATE]: "date",
  [ScopeItemType.DATETIME]: "datetime",
  [ScopeItemType.UNKNOWN]: "unknown",
  [ScopeItemType.DB_TABLE]: "database table",
  [ScopeItemType.ERROR]: "error",
  [ScopeItemType.TBD]: "tbd",
  [ScopeItemType.DELETED]: "deleted",
};

export const scopeTypeToHumanString = (type: ScopeItemType): string =>
  HUMAN_STRING_BY_SCOPE_TYPE[type] ??
  HUMAN_STRING_BY_SCOPE_TYPE[ScopeItemType.UNKNOWN];

// !!! IMPORTANT !!! This needs to be kept in sync with @hex/python-kernel-startup/python_kernel_startup/hex_scope.py

export const DataFrameSchema = Record({
  columns: Dictionary(String, String),
  columnsCount: Optional(Number),
  size: Optional(Number),
  rowCount: Optional(Number),
  rowCountIsTruncated: Optional(Boolean),
});
export type DataFrameSchema = Static<typeof DataFrameSchema>;

export const ScopeItem = Record({
  name: String,
  displayValue: String,
  type: ScopeItemTypeLiteral,
  rawType: String,
  isHidden: Boolean,
  dataFrameSchema: Union(DataFrameSchema, Null),
});
export type ScopeItem = Static<typeof ScopeItem>;

export const LegacyScope = Array(ScopeItem);
export const ScopeMetadata = Record({
  executionDurationInSeconds: Optional(Number),
});

export const ScopeWithMetadata = Record({
  metadata: Optional(ScopeMetadata),
  scopeItems: Array(ScopeItem),
});
export type ScopeWithMetadata = Static<typeof ScopeWithMetadata>;

export const Scope = Union(LegacyScope, ScopeMetadata);
export type Scope = Static<typeof Scope>;

export const MemoryStats = Record({
  total: String,
  available: String,
  max: String,
});
export type MemoryStats = Static<typeof MemoryStats>;

export const ScopeError = Record({
  message: Union(String, Null, Undefined),
  traceback: Union(String, Null, Undefined),
});
export type ScopeError = Static<typeof ScopeError>;
