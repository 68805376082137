export function isValidRedirectUrl(
  redirectUrl: string | null | undefined,
  hostname: string,
): boolean {
  if (redirectUrl == null) {
    return false;
  }

  let url: URL;
  try {
    url = new URL(
      redirectUrl,
      hostname.toLowerCase().startsWith("http")
        ? hostname
        : hostname.toLowerCase().startsWith("localhost")
          ? `http://${hostname}`
          : `https://${hostname}`,
    );
  } catch (_e) {
    return false;
  }

  if (
    (url.protocol !== "http:" && url.protocol !== "https:") ||
    url.hostname !== hostname
  ) {
    return false;
  }

  return true;
}

/**
 * Removes the first section of a URL path.
 * For example, given `/some-org/hex/abcd-efgh` returns `/hex/abcd-efgh`.
 * This is useful because our FE logic has a `basename` set which already contains the org id.
 * @param path Expected to be an absolute path starting with a `/` but without a protocol or domain
 */
export function removeOrgIdFromPath(path: string | null): string | undefined {
  if (path == null || !path.startsWith("/")) {
    return undefined;
  }

  const [_empty, _orgId, ...urlParts] = path.split("/");
  return "/" + urlParts.join("/");
}

export function replacePort(
  urlString: string,
  newPort: string | number,
): string {
  try {
    const url = new URL(urlString);
    if (!url.port) {
      throw new Error("URL does not have a port");
    }
    url.port = newPort.toString();
    return url.toString();
  } catch (e) {
    throw new Error(`Invalid URL or error in processing: ${e}`);
  }
}
