import { VegaLiteSpec, VegaSpec } from "./types";

export const isVegaLiteSpec = (
  spec: VegaLiteSpec | VegaSpec,
): spec is VegaLiteSpec =>
  // Assume if schema is null that it's a Vega-Lite spec and not Vega
  spec.$schema == null || spec.$schema.includes("/vega-lite/");

export const isVegaSpec = (spec: VegaLiteSpec | VegaSpec): spec is VegaSpec =>
  spec.$schema != null && spec.$schema.includes("/vega/");

const isV4Spec = (spec: VegaLiteSpec): boolean =>
  spec.$schema?.endsWith("v4.json") === true;

// Before we upgrade to Vega-Lite 5, we generated v4 schemas and saved them in the DB
// for VegaChartCells. However, these schemas are all v5 compatible except for having
// a wrong `'$schema'` declaration, so converting them is as easy as overwriting that.
// (There _are_ v4 features that aren't v5 compatible. We just didn't let people use any
//  of them in the limited UI we provided then.)
export const upgradeFromVegaLiteSpecV4toV5IfNecessary = (
  spec: VegaLiteSpec,
): VegaLiteSpec => {
  return isV4Spec(spec) || spec.$schema == null
    ? {
        ...spec,
        $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      }
    : spec;
};
