export const sentenceCase = (
  input: string | null | undefined,
): string | null | undefined => {
  if (input == null || input === "") {
    return input;
  }

  const withSpaces = input.replace(/_/g, " ");
  const firstChar = withSpaces.charAt(0);

  return firstChar.toUpperCase() + withSpaces.slice(1).toLowerCase();
};
