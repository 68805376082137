import { Literal, Static, Union } from "runtypes";

import { getNormalEnum } from "../runtypeEnums";

export const SqlCellActiveTabLiteral = Union(
  Literal("display"),
  Literal("preview"),
  Literal("chart"),
);
export type SqlCellActiveTab = Static<typeof SqlCellActiveTabLiteral>;
export const SqlCellActiveTab = getNormalEnum(SqlCellActiveTabLiteral);
